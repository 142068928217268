export const GREEN = 'darkgreen'
export const YELLOW = 'darkgoldenrod'
export const GREY = 'grey'

const debug =
  typeof document !== 'undefined' &&
  (document.cookie.includes('__VIDEO_DEBUG__') || document.cookie.includes('__TA_DEBUG__'))

export function log(message: string, color: string = GREEN, data: any = null): void {
  if (debug) {
    const arg = [`%c ${message}`, `background-color: ${color}; color: white`]
    if (data) arg.push(data)
    console.log(...arg)
  }
}
