export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split('; ')
  for (const cookie of cookies) {
    const [cName, cValue] = cookie.split('=')
    if (name === cName) {
      return cValue || null
    }
  }
  return null
}

function getDomain(): string {
  const domainParts = window.location.host.split('.')
  const domainLength = domainParts.length
  return '.' + domainParts[domainLength - 2] + '.' + domainParts[domainLength - 1]
}

export const setCookie = (name: string, value: string): void => {
  document.cookie = `${name}=${value}; Max-Age=${3600 * 24 * 31}; SameSite=None; Secure; path=/`
}

export function eraseCookie(name: string, domainFix = false): void {
  if (domainFix) {
    document.cookie = `${name}=; Max-Age=0; Domain=${getDomain()}; Secure; SameSite=None; path=/`
    return
  }
  document.cookie = `${name}=; Max-Age=0; SameSite=None; Secure`
}
