import { log, GREEN, YELLOW } from './log'
import { getCookie, setCookie } from './cookie'
import { setAdditionalAttributes, additionalAttributes } from './notifyNewRelic'
import { uuid } from './uuid'

const COOKIE_NAME = 'ad_detection'
const ALLOW_ADBLOCK = '__ALLOW_ADBLOCK__'

const id = uuid()
const userId = ((): string => {
  if (typeof window === 'undefined') return ''

  const storedId = getCookie(COOKIE_NAME)
  if (storedId) return storedId
  const newId = uuid()
  setCookie(COOKIE_NAME, newId)
  return newId
})()

let failedToLoadBait = false

export const BLUR_PREFIX = 'https://cldnr.talpa.network/talpa-network/image/fetch/e_blur:500,o_30,b_rgb:343434'

function isBaitBlocked(): boolean {
  if (failedToLoadBait) return true
  const elem = document.getElementById(id)
  if (
    !elem ||
    elem.offsetParent === null ||
    elem.offsetHeight == 0 ||
    elem.offsetLeft == 0 ||
    elem.offsetTop == 0 ||
    elem.offsetWidth == 0 ||
    elem.clientHeight == 0 ||
    elem.clientWidth == 0
  ) {
    return true
  } else if (window.getComputedStyle !== undefined) {
    const elemCS = window.getComputedStyle(elem, null)
    if (elemCS && (elemCS.getPropertyValue('display') == 'none' || elemCS.getPropertyValue('visibility') == 'hidden')) {
      return true
    }
  }

  return false
}

function createBaitElement(baitUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    // clean up
    const elem = document.getElementById(id)
    if (elem) elem.remove()

    const bait = document.createElement('img')
    bait.setAttribute('id', id)
    bait.setAttribute(
      'class',
      'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner',
    )
    bait.setAttribute('style', 'width: 1px !important; height: 1px !important; position: absolute !important;')
    bait.setAttribute('src', `${baitUrl}?user=${userId}&slot=pre&ad-sense=1&adId=${Date.now()}`)

    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function (): void {
      if (this.readyState === 4 && this.status >= 200 && this.status < 300) {
        log('Bait loaded', GREEN)
        failedToLoadBait = false
        const url = window.URL || window.webkitURL
        bait.setAttribute(
          'src',
          typeof url?.createObjectURL === 'function' ? url.createObjectURL(this.response) : baitUrl,
        )
        document.body.appendChild(bait)
        return resolve(false)
      }
      if (this.readyState === 4) {
        log('Bait not loaded', YELLOW)
        failedToLoadBait = true
        return resolve(true)
      }
      // request not ready yet
    }
    xhr.open('GET', baitUrl)
    xhr.responseType = 'blob'
    xhr.send()
  })
}

export async function detectAdBlock(baitUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    if (document.cookie.includes(ALLOW_ADBLOCK)) {
      return resolve(false)
    }
    createBaitElement(baitUrl).then((failed) => {
      if (failed) {
        return resolve(true)
      }
      setTimeout(() => {
        const baitBlocked = isBaitBlocked()

        if (additionalAttributes.adBlockerDetected !== baitBlocked) {
          setAdditionalAttributes({
            adBlockerDetected: baitBlocked,
          })
        }

        resolve(baitBlocked)
      }, 50)
    })
  })
}

export function preloadImage(url: string): void {
  const img = new Image()
  img.src = `${BLUR_PREFIX}/${url}`
}
