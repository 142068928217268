import axios from 'axios'
import { Feature, Condition, Match } from '../../typings/'
import { getParameterByName } from './getParameterByName'
import { isClient } from './device'
import { GREY, log, YELLOW } from './log'
import { referrer } from './referrer'
import { setDataLayer } from './tealium'

export const FEATURES = {
  ADBLOCK_DETECTION: 'adblock-detection',
  TFC_APP_VALIDATION: 'tfc-app-validation',
  DRM_MESSAGE: 'drm-message',
  TRACK_IP: 'track-ip',
  CASTING_EMBED: 'casting-embed',
  FULL_EPISODE_BUTTON: 'watch-full-episode-button',
  FULL_EPISODE_BUTTON_ON_DOMAIN: 'watch-full-episode-on-domain',
  POST_START_EVENTS: 'post-start-events',
  BLOCK_AD_SCRUB: 'block-ad-scrub',
  TALPA_RESTRICTED_SERIES: 'talpa-restricted-series',
  CUSTOM_GEO_ERROR_MESSAGE: 'custom-geo-error-message',
  ADOBE_DELAY: 'adobe-delay',
  ONE_TRUST_ENABLED: 'one-trust-enabled',
}

class FeatureTooling {
  isLoaded = false
  debug = isClient && document.cookie.includes('__FEATURE_DEBUG__')
  dataFileUrl = ''
  features: Feature[] = []
  activeFeatures: Feature[] = []
  attributes: { [name: string]: string | null } = {}
  userId = ''

  init = async (dataFileUrl: string, countryCode: string): Promise<void> => {
    this.dataFileUrl = dataFileUrl
    try {
      const { data } = await axios.get<FeatureTooling['features']>(this.dataFileUrl)
      this.features = data

      this.attributes = {
        referrer,
        countryCode,
        token: getParameterByName('token'),
      }

      this.activeFeatures = this.features.filter(({ enabled, audience, slug }) => {
        if (enabled && audience) {
          const inAudience = this.isInAudience(audience)
          log(`visitor is ${inAudience ? '' : 'not '}in audience of feature ${slug}`, GREY)
          return inAudience
        }
        return enabled
      })
      setDataLayer({
        experiment_features: this.activeFeatures.map(({ slug }) => slug).join('|'),
      })
    } catch (error) {
      log(`Failed to load data file: ${(error as any).message}`, GREY)
    }
    this.isLoaded = true
  }

  isFeatureEnabled = (featureSlug: string): boolean => {
    if (!this.isLoaded || !this.features.length) {
      return false
    }

    const enabled = this.activeFeatures.some((activeFeature) => activeFeature.slug === featureSlug)
    const feature = this.features.find(({ slug }) => slug === featureSlug)

    log(
      feature
        ? `feature ${featureSlug} is ${enabled ? '' : 'not '}enabled`
        : `feature ${featureSlug} not found in data file`,
      GREY,
    )

    return enabled
  }

  getFeatureVariable = <T>(featureSlug: string, variableKey: string, defaultValue: any = null): T | null => {
    const variables = this.getFeatureVariables<T>(featureSlug)

    if (variables) {
      return (variables as any)[variableKey] || defaultValue
    }
    return defaultValue
  }

  getFeatureVariables = <T>(featureSlug: string): T | null => {
    if (!this.isLoaded || !this.features.length || !this.isFeatureEnabled(featureSlug)) {
      return null
    }

    const { variables } = this.features.find(({ slug }) => slug === featureSlug) || {}
    return variables
  }

  isInAudience = (audience: string): boolean => {
    try {
      const [relation, ...conditions] = JSON.parse(audience) as ['and' | 'or', ...Condition[]]

      const getCurrentValue = (name: string): string => {
        if (name === 'userId') return this.userId || ''
        if (name in this.attributes) return this.attributes[name] || ''
        return ''
      }

      const conditionMet = ({ match, name, value }: Condition): boolean => {
        const currentValue = getCurrentValue(name)
        if (match === Match.SUBSTRING) return currentValue.includes(value)
        if (match === Match.EXACT) return currentValue === value
        if (match === Match.DIFFERENT) return currentValue !== value
        if (match === Match.NOSUBSTRING) return !currentValue.includes(value)
        return false
      }

      if (relation === 'and') {
        return conditions.every(conditionMet)
      }
      return conditions.some(conditionMet)
    } catch (error) {
      log((error as any).message, YELLOW)
    }
    return false
  }
}

const featureTooling = new FeatureTooling()
if (isClient) {
  ;(window as any).__featureTooling = featureTooling
}

export default featureTooling
