import Tealium from '@4tn/talpatv-video-analytics'
import { version } from '@4tn/tv-npm-player/dist/config'
import { log } from '.'

export const initTealium = async (environment: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    Tealium.setup({
      account: 'talpa',
      profile: 'video-kijk-embed',
      environment,
      defaultLayer: {
        app_name: 'embed player',
        app_version: version,
      },
    })
      .then(() => {
        log('Tealium loaded successfully')
        resolve()
      })
      .catch((error) => {
        reject(`Taq could not be loaded: ${error}`)
      })
  })
}

export function setDataLayer(data: { [key: string]: string | boolean | null }): void {
  Tealium.setDataLayer(data)
}

export function trackView(data: { [key: string]: string | boolean | null }): void {
  Tealium.view({ event_category: 'PAGEVIEW', event_name: 'pageview', ...data })
}

export const trackClick = (label = ''): void => {
  Tealium.link({
    event_name: 'player_click',
    event_category: 'player',
    event_label: label,
  })
}
