export const isClient = typeof window !== 'undefined'

export function isAndroidChrome(): boolean {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('android') >= 0 && ua.indexOf('chrome') >= 0
}
export function getChromeVersion(): number {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
  return raw ? parseInt(raw[2], 10) : 0
}
