export interface Feature {
  slug: string
  enabled: boolean
  variables?: any
  audience?: string
}

export interface Condition {
  name: string
  match: 'exact' | 'substring' | 'different' | 'nosubstring'
  value: string
}

export enum Match {
  EXACT = 'exact',
  SUBSTRING = 'substring',
  DIFFERENT = 'different',
  NOSUBSTRING = 'nosubstring',
}
