import axios from 'axios'
import { preloadImage } from './adBlockDetection'

/**
 * This works only for production video andis a temporary solution
 * until https://talpa.atlassian.net/browse/KW-854 is in place
 * */
export default async function getLandscapeImage(guid: string): Promise<string> {
  try {
    const query = encodeURIComponent(`{ programs(guid: "${guid}") { items { imageMedia { label url } } } }`)
    const { data } = await axios.get<{
      data: { programs: { items: { imageMedia: { url: string; label: string }[] }[] } }
    }>(`https://api.prd.video.talpa.network/graphql?query=${query}`)
    const { url } = data.data.programs.items[0].imageMedia.find((image) => image.label === 'editorial_landscape') || {}
    if (url) {
      const image = `ar_16:9,c_fill,h_540,w_auto/${url}`
      preloadImage(image)
      return image
    }
  } catch (ignore) {}
  return ''
}
