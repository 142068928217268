import React, { ReactNode, useState } from 'react'
import { AppProps } from 'next/app'
import '@4tn/tv-npm-player/dist/styles/style.css'
import {
  initTealium,
  checkConsent,
  detectAdBlock,
  setDataLayer,
  loadOneTrustSDK,
  inIframe,
  reloadCurrentPage,
  isOnTalpaDomain,
} from '../src/utils'
import GlobalStyle from '@/styles/globalStyle'
import { TalpaConsentData } from '../typings/'
import featureTooling from '../src/utils/featureTooling'
import LoaderIcon from '../src/components/LoaderIcon'
import { TPageProps } from '../typings/pageProps'

declare function __tcfapi(
  cmd: string,
  version: number,
  callback: (data: { tcString: string }, success: boolean) => void,
): void

const EmbedWrapperApp = ({ Component, pageProps }: AppProps<TPageProps>): ReactNode => {
  const [consentData, setConsentData] = useState<TalpaConsentData>()

  React.useEffect(() => {
    if (inIframe || typeof __tcfapi === 'undefined' || !consentData?.tcString) {
      return
    }

    function handleConsentChange(data: { tcString: string }, success: boolean) {
      if (success && data.tcString !== consentData?.tcString) {
        reloadCurrentPage()
      }
    }

    __tcfapi('addEventListener', 2, handleConsentChange)
    return () => {
      __tcfapi('removeEventListener', 2, handleConsentChange)
    }
  }, [consentData])

  React.useEffect(() => {
    const { baitUrl, dataFileUrl, tealiumEnv, countryCode, oneTrustId, env } = pageProps
    window.TN_GDPR_BYPASS = true
    window.WAIT_FOR_OT_PROXY = isOnTalpaDomain

    featureTooling.init(dataFileUrl, countryCode).then(() => {
      Promise.all([detectAdBlock(baitUrl), loadOneTrustSDK(oneTrustId, env), initTealium(tealiumEnv)])
        .then(([detectedAdBlocker]) => {
          setDataLayer({
            app_adsenseid: `adblok:${detectedAdBlocker}`,
          })
          checkConsent().then(setConsentData)
        })
        .catch((error) => {
          setConsentData({ tcString: '', hasTalpaConsent: false })
          if (typeof window.newrelic === 'object') window.newrelic.noticeError(error)
        })
    })
  }, [])

  if (!consentData) return <LoaderIcon />

  return (
    <>
      <GlobalStyle />
      <Component consentData={consentData} {...pageProps} />
    </>
  )
}

export default EmbedWrapperApp
