export const preventScrubbingDuringAds = (jwplayer: jwplayer.JWPlayer) => {
  let adDuration: number | null = null
  let position = 0
  const video = document.querySelector('video')
  if (!video) {
    return
  }

  video.onseeking = () => {
    if (!adDuration || adDuration < 5) return

    const diff = adDuration - video.currentTime
    if (diff < 3) {
      video.currentTime = 0
    }
  }

  jwplayer.on('adTime', (event: jwplayer.AdTimeParam) => {
    const positionDiff = event.position - position
    if (adDuration !== event.duration) {
      adDuration = event.duration
    }

    if (positionDiff > 1) {
      video.currentTime = position
      return
    }
    position = event.position
  })

  jwplayer.on('adBreakEnd' as keyof jwplayer.EventParams, () => {
    adDuration = null
  })
}
