import { getParameterByName } from './getParameterByName'

const talpaDomains = [
  '538.nl',
  'gids.tv',
  'hartvannederland.nl',
  'juke.nl',
  'www.kijk.nl',
  'kijkers.tv ',
  'linda.nl',
  'new-wave.nl ',
  'radio10.nl',
  'radioveronica.nl ',
  'sbs.nl',
  'show.nl',
  'shownieuws.nl',
  'skyradio.nl',
  'social1nfluencers.nl',
  'stuk.tv',
  'talpanetwork.com',
  'talpaplayer.nl',
  'talpavideo.nl',
  'veronicainside.nl',
  'vivandaag.nl',
  'weer.nl',
  'vandaaginside.nl',
]

export const referrer = (function getReferrer(): string | null {
  if (typeof window === 'undefined') return null

  const referrerFromUrl = getParameterByName('referrer')
  if (referrerFromUrl) return referrerFromUrl

  try {
    const host = document.referrer || document.location.ancestorOrigins[0]
    return new URL(host).hostname
  } catch {}
  return null
})()

export const isOnTalpaDomain = talpaDomains.some((talpaDomain) => referrer?.includes(talpaDomain))
