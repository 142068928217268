export const TRANSLATIONS = {
  SOMETHING_WENT_WRONG: 'Oeps, er is iets misgegaan!',
  DEVICE_NOT_SUPPORTED: 'Deze video kan hier niet worden afgespeeld.',
  TRY_AGAIN: 'Probeer opnieuw',
  WATCH_VIDEO: 'Bekijk de video hier',
  NOT_YET_AVAILABLE: 'De video is helaas nog niet beschikbaar',
  NOT_AVAILABLE_ANYMORE: 'De video is helaas niet meer beschikbaar',
  NOT_FOUND: 'Video helaas niet gevonden',
  ADBLOCK_OFF: 'Ik heb de ad blocker uitgezet',
  ADBLOCK_INFO: `Je gebruikt een adblocker, zet deze uit om de leukste programma's, fragmenten en films te kunnen bekijken`,
  GO_TO_KIJK: 'Ga naar KIJK om deze video te bekijken',
  GEO_BLOCKED: 'Deze video is niet beschikbaar op je huidige locatie',
}

export const GRADIENT_BACKGROUND =
  'https://cldnr.talpa.network/talpa-network/image/fetch/ar_16:9,c_fill,h_540,w_auto/https://static.kijk.nl/images/gradient-background.jpg'

export const AD_BLOCK_KEY = '__ALLOW_ADBLOCK__'

export const SECRET_NAME = 'no-ads-for-me'

export const BREAKPOINTS = {
  smMax: 640,
  lg: 1280,
}

export const RESTRICTED_VIDEO_ERROR = 'RESTRICTED_VIDEO_ERROR'

export const AVAILABLE_REGIONS = {
  NETHERLANDS: 'NL',
  WORLDWIDE: 'WW',
}

export const INFO_IMAGES = {
  BROKEN_TV: 'https://static.kijk.nl/images/broken-tv.png',
  ALERT: 'https://static.kijk.nl/images/alert.svg',
  DRM: 'https://static.kijk.nl/images/drm-message.png',
}
