import React from 'react'
import * as Styled from './LoaderIcon.styled'

const LoaderIcon: React.FC = () => {
  return (
    <Styled.LoaderContainer>
      <Styled.Loader>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="spin" viewBox="0 0 32 32">
          <defs>
            <linearGradient id="linearGradient-1" x1="50%" x2="99.905%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#EEE" />
              <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="url(#linearGradient-1)" transform="translate(-464 -344)">
              <g transform="translate(464 338)">
                <path d="M16 5.997v6.001A9.996 9.996 0 007.34 17C4.578 21.783 6.217 27.899 11 30.66c4.783 2.762 10.899 1.123 13.66-3.66a9.964 9.964 0 001.292-4h6.02a15.934 15.934 0 01-2.116 7C25.438 37.653 15.653 40.275 8 35.856.347 31.438-2.275 21.653 2.144 14 5.104 8.872 10.476 6.002 16 5.997z" />
              </g>
            </g>
          </g>
        </svg>
      </Styled.Loader>
    </Styled.LoaderContainer>
  )
}

export default LoaderIcon
