import { inIframe } from './inIframe'
import { log } from './log'

const cmpCallbacks = {}

export enum LOCATORS {
  TCF = '__tcfapi',
  CMP = '__cmp',
}

export function getLocator(type: LOCATORS): any {
  let frame: any = window // if we locate the CMP iframe we will reference it with this
  let cmpFrame // map of calls
  while (frame) {
    try {
      // throws a reference error if no frames exist
      if (frame.frames[`${type}Locator`]) {
        cmpFrame = frame
        break
      }
    } catch (ignore) {}
    if (frame === window.top) {
      break
    }
    frame = frame.parent
  }

  return cmpFrame
}

export function addTcfProxy(cmpFrame: any): void {
  ;(window as any).__tcfapi = (cmd: string, version: number, callback: any, arg: any): void => {
    const callId = Math.random() + ''
    const msg = {
      __tcfapiCall: {
        command: cmd,
        parameter: arg,
        version: version,
        callId: callId,
      },
    }
    // map the callback for lookup on response
    cmpCallbacks[callId] = callback
    cmpFrame.postMessage(msg, '*')
  }

  const postMessageHandler = (event: any): void => {
    // when we get the return message, call the mapped callback
    let json: any = {}

    try {
      // if this isn't valid JSON then this will throw an error
      json = typeof event.data === 'string' ? JSON.parse(event.data) : event.data
    } catch (ignore) {}

    const payload = json.__tcfapiReturn

    if (payload) {
      // messages we care about will have a payload
      if (typeof cmpCallbacks[payload.callId] === 'function') {
        // call the mapped callback and then remove the reference
        cmpCallbacks[payload.callId](payload.returnValue, payload.success)

        // Todo, clear cmpCallbacks for removeEventListener
        if (payload.command !== 'addEventListener') {
          cmpCallbacks[payload.callId] = null
        }
      }
    }
  }

  window.addEventListener('message', postMessageHandler, false)
}

export function waitForTcfInParent(): Promise<void> {
  const retryLimit = 5
  let retry = 0
  return new Promise(async (resolve) => {
    const interval = setInterval(async () => {
      const cmpFrame = getLocator(LOCATORS.TCF) || getLocator(LOCATORS.CMP)
      if (cmpFrame) {
        log('found tcf locator in parent')
        clearInterval(interval)
        resolve()
        return
      }
      if (retry >= retryLimit) {
        clearInterval(interval)
        resolve()
        return
      }
      retry++
    }, 100)
  })
}

// Add tcfApi and tcfApi Locator
export function mockTcfApi(tcString: string): void {
  ;(window as any).TCF_MOCKED = true
  ;(window as any).__tcfapi = (cmd: string, version: number, callback: any): void => {
    try {
      callback(
        {
          cmpStatus: 'loaded',
          eventStatus: 'tcloaded',
          cmpVersion: version,
          gdprApplies: true,
          tcString,
        },
        true,
      )
    } catch (error) {}
  }

  if (!inIframe) return

  const locator = window.document.createElement('iframe')
  locator.style.cssText = 'display:none'
  locator.name = '__tcfapiLocator'
  window.document.body.appendChild(locator)

  window.addEventListener(
    'message',
    (e: MessageEvent) => {
      let data: any = {}
      const stringData = typeof e.data === 'string'
      try {
        data = stringData ? JSON.parse(e.data) : e.data
      } catch (e) {}
      const tcfRequest = data.__tcfapiCall
      if (tcfRequest && e.source) {
        let response: any = {
          __tcfapiReturn: {
            returnValue: {
              cmpStatus: 'loaded',
              eventStatus: 'tcloaded',
              cmpVersion: tcfRequest.version,
              gdprApplies: true,
              tcString,
            },
            success: true,
            callId: tcfRequest.callId,
            command: tcfRequest.command,
          },
        }
        if (stringData) {
          response = JSON.stringify(response)
        }

        ;(e.source as MessagePort).postMessage(response, '*' as any)
      }
    },
    false,
  )
}
