import { Program } from '@4tn/tv-npm-player'
import { AVAILABLE_REGIONS, INFO_IMAGES, TRANSLATIONS } from '../constants'
import { BLUR_PREFIX } from './adBlockDetection'
import { trackClick } from './tealium'
import featureTooling, { FEATURES } from './featureTooling'

const DEFAULT_BACKGROUND = 'https://kijk.nl/static/images/default_editorial_landscape.png'
const ERROR_CODE_PREFIX = 'Foutcode: '
function getErrorDetails({
  item,
  error,
  drmSupported,
}: {
  item?: Program
  error: jwplayer.ErrorParam
  drmSupported: boolean
}): {
  text: string
  hasClickOut?: boolean
} {
  if (
    item?.availableRegion === AVAILABLE_REGIONS.NETHERLANDS &&
    featureTooling.isFeatureEnabled(FEATURES.CUSTOM_GEO_ERROR_MESSAGE)
  ) {
    return {
      text: TRANSLATIONS.GEO_BLOCKED,
    }
  }
  if (!drmSupported) {
    return {
      text: TRANSLATIONS.DEVICE_NOT_SUPPORTED,
      hasClickOut: true,
    }
  }

  return {
    text: `${error.message || TRANSLATIONS.SOMETHING_WENT_WRONG}\n${ERROR_CODE_PREFIX}${error.code}`,
  }
}

export default function styleError({
  error,
  item,
  drmSupported,
}: {
  error: jwplayer.ErrorParam
  item?: Program
  drmSupported: boolean
}): void {
  const errorContainer = document.querySelector<HTMLDivElement>('.jw-error, .jw-state-error')
  const errorMessage = document.querySelector<HTMLDivElement>('.jw-error .jw-error-msg, .jw-state-error .jw-error-msg')
  if (!errorContainer || !errorMessage) return
  const { text, hasClickOut } = getErrorDetails({ item, error, drmSupported })
  // hide default info and icon
  document
    .querySelectorAll<HTMLDivElement>(
      '.jw-error .jw-icon, .jw-error .jw-info-container, .jw-state-error .jw-icon, .jw-state-error .jw-info-container',
    )
    .forEach((elem) => {
      elem.style.display = 'none'
    })

  errorMessage.classList.add('full-width-height')

  Object.assign(errorMessage.style, {
    backgroundImage: `url(${BLUR_PREFIX}/${item?.image || DEFAULT_BACKGROUND})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  })

  const image = document.createElement('img')
  Object.assign(image, {
    src: INFO_IMAGES.ALERT,
    alt: text,
  })

  const errorInfo = document.createElement('p')
  errorInfo.innerText = text

  errorMessage.prepend(image, errorInfo)

  if (item && hasClickOut) {
    const clickOutButton = document.createElement('button')
    Object.assign(clickOutButton, {
      className: 'btn',
      innerText: TRANSLATIONS.WATCH_VIDEO,
      onclick: () => {
        trackClick(TRANSLATIONS.WATCH_VIDEO)
        window.open(`https://www.kijk.nl/video/${item.guid}`, '_blank')
      },
    })
    errorMessage.append(clickOutButton)
  }
}
