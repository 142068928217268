import { createGlobalStyle } from 'styled-components'
import { BREAKPOINTS } from '../constants'

const GlobalStyle = createGlobalStyle`
html, 
body, 
div,
p,
img,
h1,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

/* Global styles */
html,
body,
#__next {
  height: 100%; 
}

@font-face {
  font-family: Cera;
  src: url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.eot');
  src: url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.woff') format('woff'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.woff2') format('woff2'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: Cera;
  src: url('https://static.prod.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.eot');
  src: url('https://static.prod.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.woff') format('woff'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.woff2') format('woff2'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_bold/CeraPro-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

body {
  text-align: center;
  font-family: Cera, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  background-color: #151515;
  overflow: hidden;
  line-height: 1;
}

/* jwplayer styles */

.jw-error, .jw-state-error {
  .btn {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    max-width: 320px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    background-color: #33c497;
    color: white;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }
  
  .jw-error-msg {
    font-size: 16px;

    img {
      width: 70px;
      height: 70px;
      margin-bottom: 30px;
      display: block;
    }

    p {
      font-size: 18px;
      line-height: 1.35;
      text-align: center;
    }

    .btn {
      margin-top: 10px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.jw-error-msg.full-width-height {
  flex-direction: column !important;
  justify-content: center;
  align-items: center !important;
  height: 100vh;
  width: 100vw;
}

@media (max-width:${BREAKPOINTS.smMax}px) {
  .jwplayer {
    height: 100vh !important;
  }

  .jw-error .jw-error-msg {
    font-size: 14px;
  }

  .jw-state-error .jw-error-msg {
    font-size: 14px;

    img{
      width: 48px;
      height: 48px;
    }
  }
}


#ot-sdk-btn-floating.ot-floating-button { 
  height: 40px !important;
  width: 40px !important;
  bottom: 60px !important;

  @media (min-width:${BREAKPOINTS.lg}px) {
    bottom: 30px !important;
    left: 13px !important;
  }
}

[button='cookie-setting-button'] .jw-button-image {
  background-image: url('https://cdn.cookielaw.org/logos/static/ot_persistent_cookie_icon.png') !important;
}

.jwplayer.jw-state-idle {
  [button='cookie-setting-button'] {
    display: block !important;

    .jw-icon {
      display: block !important;
    }
  }
}
`

export default GlobalStyle
