import { TCString } from '@iabtcf/core'
import { inIframe, isEmbedded } from './inIframe'
import { addTcfProxy, waitForTcfInParent, mockTcfApi, getLocator, LOCATORS } from './tcfProxy'
import { ConsentData, TalpaConsentData } from '../../typings/'
import { log, YELLOW, GREY } from './log'
import { getParameterByName } from './getParameterByName'
import { customInteraction } from './notifyNewRelic'
import featureTooling, { FEATURES } from './featureTooling'

type OneTrustDomainData = {
  dataLayer: { event: string; OnetrustActiveGroups: string }[]
  Groups: {
    GroupName: string
    OptanonGroupId: string
    Type: 'COOKIE' | 'IAB2_PURPOSE'
  }[]
}
declare global {
  interface Window {
    OneTrust?: {
      ToggleInfoDisplay?: () => void
    }
    OnetrustActiveGroups?: string
    WAIT_FOR_OT_PROXY: boolean
    tealiumCmpIntegration?: {
      cmpCheckForWellFormedDecision: (consent: OneTrustDomainData) => boolean
      cmpFetchCurrentConsentDecision: () => OneTrustDomainData
    }
    __tcfapi?(
      cmd: string,
      version: number,
      callback: (data: { tcString: string; msg: string }, success: boolean) => void,
    ): void
  }
}

export const OT_CONSENT_KEY = 'eupubconsent-v2' // OneTrust
export const EU_CONSENT_KEY = 'euconsent-v2' // LiveRamp

const TCF_TIMEOUT = 1000

function getTalpaConsent(tcString: string): boolean {
  if (!tcString) return false
  try {
    const decodedConsent = TCString.decode(tcString)
    if (!decodedConsent) return false

    const hasConsent = [2, 3, 4, 7, 9].every((purpose) => decodedConsent.purposeConsents.has(purpose))
    return hasConsent
  } catch (error) {
    if (typeof window.newrelic === 'object') window.newrelic.noticeError(error)
    return false
  }
}

const tcfapi = async (cmd: string): Promise<{ tcData: any; success: boolean }> =>
  new Promise((resolve) => {
    ;(window as any).__tcfapi(cmd, 2, (tcData: any, success: boolean) =>
      resolve({
        tcData,
        success,
      }),
    )
  })

function getTCData(): Promise<ConsentData | null> {
  return new Promise(async (resolve) => {
    if (typeof (window as any).__tcfapi !== 'function') {
      return resolve(null)
    }
    const timeout = setTimeout(() => {
      log(`No response from __tcfapi after ${TCF_TIMEOUT}ms`, YELLOW)
      delete (window as any).__tcfapi
      return resolve(null)
    }, TCF_TIMEOUT)

    let { tcData, success } = await tcfapi('getTCData')
    log('TCData', GREY, { tcData, success })
    if (success === false && tcData === null) {
      log('Retry', YELLOW)
      ;({ tcData, success } = await tcfapi('getTCData'))
      log('TCData', GREY, { tcData, success })
    }

    clearTimeout(timeout)

    const { tcString } = tcData || {}
    if (tcString) {
      return resolve({
        tcString,
      })
    }
    return resolve(null)
  })
}

async function getTCString(): Promise<string | null> {
  // TCF loaded
  if (typeof (window as any).__tcfapi === 'function') {
    log('tcfApi available')
    return (await getTCData())?.tcString || null
  }

  // Consent From URL
  const tcString = getParameterByName(OT_CONSENT_KEY) || getParameterByName(EU_CONSENT_KEY)
  if (tcString) {
    log(`Found tcString in URL | ${tcString}`)
    mockTcfApi(tcString)
    return tcString
  }

  // Consent From Parent
  if (inIframe) {
    await waitForTcfInParent() // TCF api might not have been loaded yet, so we need to wait
  }

  const tcfFrame = getLocator(LOCATORS.TCF)
  if (tcfFrame) {
    log('Adding addTcfProxy')
    addTcfProxy(tcfFrame)
    return (await getTCData())?.tcString || null
  }

  const cmpFrame = getLocator(LOCATORS.CMP)
  if (cmpFrame) {
    log('Adding addTcfProxy')
    addTcfProxy(cmpFrame)
    const { tcString } = (await getTCData()) || {}
    if (tcString) {
      log('Mock TCF API', GREY, tcString)
      // the freewheel plugin is only able to use __tcfapiLocator so we need to mock it here
      mockTcfApi(tcString)
      return tcString
    }
  }

  log('no tcString found', YELLOW)
  return null
}

export const loadOneTrustSDK = (domainId: string, env: string): Promise<void> =>
  new Promise((resolve, reject) => {
    if ((inIframe || isEmbedded) && !featureTooling.isFeatureEnabled(FEATURES.ONE_TRUST_ENABLED)) return resolve()

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = function (): void {
      const maxRetries = 20
      let retry = 0
      const interval = setInterval(() => {
        if (window.OnetrustActiveGroups || retry++ >= maxRetries) {
          clearInterval(interval)
          resolve()
        }
      }, 100)
    }

    const otUrl =
      inIframe || isEmbedded
        ? `https://static${env === 'prod' ? '' : `.${env}`}.kijk.nl/lib/consentproxy/otSDKStub.js`
        : 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'

    script.src = otUrl

    script.onerror = function (): void {
      reject(`Error loading script: ${otUrl}`)
    }
    script.setAttribute('data-domain-script', domainId)
    script.async = true
    document.head.appendChild(script)
  })

export async function checkConsent(): Promise<TalpaConsentData> {
  const tcString = await getTCString()
  if (!tcString) {
    return { tcString: '', hasTalpaConsent: false }
  }
  return { tcString, hasTalpaConsent: getTalpaConsent(tcString) }
}

export function validateTcf(): void {
  if (typeof window.__tcfapi === 'undefined') {
    customInteraction('tcf result: no __tcfapi')
    return
  }
  window.__tcfapi('getTCData', 2, (tcData, success) => {
    log('getTCData result', success ? GREY : YELLOW, {
      tcData,
      success,
    })
    const msg = tcData?.msg ? ` (${tcData.msg})` : ''
    customInteraction(`tcf result: ${success ? 'success' : 'failed'}${msg}`)
  })
}

export function addConsentButton(jwplayer: jwplayer.JWPlayer): void {
  // Hide Default Consent Button
  const consentButton = document.getElementById('ot-sdk-btn-floating')
  if (consentButton) {
    consentButton.style.visibility = 'hidden'
  }

  if (typeof window.OneTrust?.ToggleInfoDisplay === 'function') {
    jwplayer.addButton(
      '',
      'Cookie Instellingen',
      () => {
        window.OneTrust?.ToggleInfoDisplay?.()
      },
      'cookie-setting-button',
    )
  }
}
