import { inIframe } from './inIframe'
import { referrer } from './referrer'

export default function reloadCurrentPage(): void {
  const url = new URL(document.location.href)
  if (inIframe && referrer) {
    url.searchParams.set('referrer', referrer)
  }
  window.location.assign(url.href)
}
