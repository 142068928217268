declare global {
  interface Window {
    newrelic?: any
  }
}

export let additionalAttributes: { [key: string]: string | boolean | null } = {}

export const setAdditionalAttributes = (data: { [key: string]: string | boolean | null }): void => {
  additionalAttributes = { ...additionalAttributes, ...data }

  if (typeof window.newrelic === 'object') {
    Object.keys(data).forEach((key) => {
      window.newrelic.setCustomAttribute(key, data[key])
    })
  }
}

export const CONSTANTS = {
  SETUP_ERRORS_MISCELLANEOUS: 'Setup Errors - Miscellaneous',
  SETUP_ERRORS_LOADING_JAVASCRIPT_COMPONENTS: 'Setup Errors - Loading Javascript Components',
  PLAYLIST_PARSING: 'Setup Errors - Playlist Parsing',
  EMPTY_PLAYLIST: 'Setup Errors - Empty Playlist',
  PLAYER_ERROR_MISCELLANEOUS: 'Player Errors - Miscellaneous',
  LOADING_NEW_PLAYLIST: 'Player Errors - Loading New Playlist',
  PLAYLIST_ITEM: 'Player Errors - Playlist Item',
  PLAYER_ERROR_LOADING_JAVASCRIPT_COMPONENTS: 'Player Errors - Loading Javascript Components',
  MEDIA_PLAYBACK_FLASH: 'Player Errors - Media Playback Flash',
  MEDIA_PLAYBACK_HTML: 'Player Errors - Media Playback HTML5',
  MEDIA_PLAYBACK_HLS: 'Player Errors - Media Playback HLS.JS',
  MEDIA_PLAYBACK_SHAKA: 'Player Errors - Media Playback Shaka',
  UNKNOWN_ERROR: 'Unknown jwplayer error code',
  PLAY_ATTEMPT_FAIL: 'Warning - playAttemptFailed',
  WARNING_LOADING_JAVASCRIPT_COMPONENTS: 'Warning - Loading Javascript Components',
  RELATED: 'Warning - Related',
  CAPTIONS: 'Warning - Captions',
  VR: 'Warning - VR',
  LOCALIZATION: 'Warning - Localization',
  WARNING_MEDIA_PLAYBACK: 'Warning - Media Playback HLS.JS',
  CASTING: 'Warning - Casting',
  UNKNOWN_WARNING: 'Unknown jwplayer warning',
}

export const getErrorGroup = (errorCode: number): string => {
  switch (true) {
    case errorCode >= 100000 && errorCode <= 100014:
      return CONSTANTS.SETUP_ERRORS_MISCELLANEOUS
    case errorCode >= 102630 && errorCode <= 102700:
      return CONSTANTS.EMPTY_PLAYLIST
    case errorCode >= 102000 && errorCode <= 102621:
      return CONSTANTS.PLAYLIST_PARSING
    case errorCode >= 101100 && errorCode <= 104153:
      return CONSTANTS.SETUP_ERRORS_LOADING_JAVASCRIPT_COMPONENTS
    case errorCode === 200001:
      return CONSTANTS.PLAYER_ERROR_MISCELLANEOUS
    case errorCode >= 202000 && errorCode <= 202630:
      return CONSTANTS.LOADING_NEW_PLAYLIST
    case errorCode >= 203000 && errorCode <= 203640:
      return CONSTANTS.PLAYLIST_ITEM
    case errorCode >= 204100 && errorCode <= 204154:
      return CONSTANTS.PLAYER_ERROR_LOADING_JAVASCRIPT_COMPONENTS
    case errorCode >= 210000 && errorCode <= 214000:
      return CONSTANTS.MEDIA_PLAYBACK_FLASH
    case errorCode >= 220001 && errorCode <= 226599:
      return CONSTANTS.MEDIA_PLAYBACK_HTML
    case errorCode >= 230000 && errorCode <= 239000:
      return CONSTANTS.MEDIA_PLAYBACK_HLS
    case errorCode >= 240000 && errorCode <= 340000:
      return CONSTANTS.MEDIA_PLAYBACK_SHAKA
    default:
      return CONSTANTS.UNKNOWN_ERROR
  }
}
const getWarningGroup = (errorCode: number): string => {
  switch (true) {
    case errorCode >= 303200 && errorCode <= 303230:
      return CONSTANTS.PLAY_ATTEMPT_FAIL
    case errorCode >= 302001 && errorCode <= 302611:
      return CONSTANTS.RELATED
    case errorCode >= 301121 && errorCode <= 305107:
      return CONSTANTS.WARNING_LOADING_JAVASCRIPT_COMPONENTS
    case errorCode >= 306001 && errorCode <= 306009:
      return CONSTANTS.CAPTIONS
    case errorCode >= 307000 && errorCode <= 307012:
      return CONSTANTS.VR
    case errorCode >= 308000 && errorCode <= 308640:
      return CONSTANTS.LOCALIZATION
    case errorCode >= 330000 && errorCode <= 339000:
      return CONSTANTS.WARNING_MEDIA_PLAYBACK
    case errorCode === 350000:
      return CONSTANTS.CASTING
    default:
      return CONSTANTS.UNKNOWN_WARNING
  }
}

export function notifyNewRelic(info: any, playlistItem: any = null): void {
  if (typeof window.newrelic !== 'object') {
    return
  }
  const customErrorAttributes: { [key: string]: string | boolean | null } = {
    ...additionalAttributes,
    error_code: info.code.toString(),
    error_type: info.type,
    error_key: info.key,
    error_details: null,
    error_response_code: false,
    error_group: '',
    media_videotype: null,
  }

  if (playlistItem) {
    customErrorAttributes.media_id = playlistItem.metadata.media_id
    customErrorAttributes.media_videotype = playlistItem.metadata.media_videotype
  }

  if (customErrorAttributes)
    if (info.sourceError) {
      if (info.sourceError.details) {
        customErrorAttributes.error_details = info.sourceError.details
      }
      if (info.sourceError.response?.code) {
        customErrorAttributes.error_response_code = info.sourceError.response.code.toString()
      }
    }
  if (info.type === 'error' || info.type === 'setupError') {
    customErrorAttributes.error_group = getErrorGroup(info.code)
  } else if (info.type === 'warning') {
    customErrorAttributes.error_group = getWarningGroup(info.code)
  }
  // TODO: switch case on info.type to send error or warning, if notifyWarning exists...
  window.newrelic.noticeError(info, customErrorAttributes)
}

export function onErrorOrWarning(event: any): void {
  const playlistItem = (window as any).jwplayer().getPlaylistItem()
  notifyNewRelic(event, playlistItem)
}

export function noticeError(
  msg: string,
  customAttributes: { [key: string]: string | boolean | boolean | null } = {},
): void {
  if (typeof window.newrelic !== 'object') return

  window.newrelic.noticeError(new Error(msg), customAttributes)
}

export function customInteraction(msg: string): void {
  if (typeof window.newrelic !== 'object') return

  window.newrelic.interaction().actionText(msg).save()
}
